<template>
  <div class="blank">加载中...</div>
</template>

<script>
export default {
  name: "blank",
  data() {
    return {};
  },
  async mounted() {
    window.location.href =
      "https://gxq.zhuneng.cn/d3m/#/s/f5960c2a51ab4f2cb0c2d287176347a3";
  },
};
</script>

<style lang="less" scoped>
.blank {
  text-align: center;
  font-size: 24px;
}
</style>
